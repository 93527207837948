import React, { useState } from 'react';
import { Container, Text, List, Link, Message } from 'basis';
import { ButtonToggle } from 'components';
import { byCard, getProductName } from 'utils';
import {
  APPLY_BTN_LABEL,
  OverviewSources,
  PRODUCT_CODE_AFS,
  PRODUCT_CODE_AU_GEM,
  PRODUCT_CODE_GO,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
  config,
} from '_config';
import { DEV_FEATURES } from '__dev';
import { LandingContentItem, ResponsiveList } from './LandingPageAu.styles';

import { VerifierModal } from '../landing-page-dj/VerifierModal';
import { DeclarationModal } from '../landing-page-dj/DeclarationModal';
import { PrivacyNoticeModal } from './PrivacyNoticeModal';

import { StartApplicationButton } from './StartApplicationButton';

const productBenefits = {
  [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: [
    'the ability to make everyday credit card purchases in-store and online at businesses who accept Mastercard and perform other transactions.',
    'pay no international transaction or currency conversion fees on purchases; and',
    'access to a Rewards program to earn redeemable amounts to purchase an e-Gift card on eligible transactions within a statement period?',
  ],
  [PRODUCT_CODE_AFS]: ['interest free payment plans, and', 'the ability to perform other transactions if required?'],
  default: [
    'interest free payment plans, and',
    'the ability to make everyday credit card purchases and / or perform other transactions if required?',
  ],
};

export const ConfirmAndStart = ({
  trackEventSender,
  formTealiumLocation,
  theme,
  isAdblockCheckComplete,
  isAdblockerDetected,
  loading,
  formData,
}) => {
  const benefitList = byCard(productBenefits);

  const [verifierModalOpen, setVerifierModalOpen] = useState(false);
  const [declarationModalOpen, setDeclarationModalOpen] = useState(false);
  const [privacyNoticeModalOpen, setPrivacyNoticeModalOpen] = useState(false);
  const keyFactsSheetLink = byCard({
    [PRODUCT_CODE_GO]: OverviewSources.AU.go_key_facts_sheet,
    [PRODUCT_CODE_AFS]: OverviewSources.AU.afs_key_facts_sheet,
    [PRODUCT_CODE_AU_GEM]: OverviewSources.AU.key_facts_sheet,
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: OverviewSources.AU.twenty_eight_degrees_key_facts_sheet,
  });

  const conditionsOfUseLink = byCard({
    [PRODUCT_CODE_GO]: OverviewSources.AU.go_conditions_of_use,
    [PRODUCT_CODE_AFS]: OverviewSources.AU.afs_conditions_of_use,
    [PRODUCT_CODE_AU_GEM]: OverviewSources.AU.gem_conditions_of_use,
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: OverviewSources.AU.twenty_eight_degrees_conditions_of_use,
  });

  const openVerifierModal = () => {
    setVerifierModalOpen(true);
    trackEventSender({
      action: 'interaction',
      location: formTealiumLocation,
      label: "Verifier's terms of service",
    });
  };

  const closeVerifierModal = () => {
    setVerifierModalOpen(false);
  };

  const openDeclarationModal = () => {
    setDeclarationModalOpen(true);
    trackEventSender({
      action: 'interaction',
      location: formTealiumLocation,
      label: 'Declaration and Credit Application',
    });
  };

  const closeDeclarationModal = () => {
    setDeclarationModalOpen(false);
  };

  const openPrivacyNoticeModal = () => {
    setPrivacyNoticeModalOpen(true);
    trackEventSender({
      action: 'interaction',
      location: formTealiumLocation,
      label: 'Privacy Notice',
    });
  };

  const closePrivacyNoticeModal = () => {
    setPrivacyNoticeModalOpen(false);
  };

  const items = [
    {
      left: 'Please make sure this product is right for you',
      right: (
        <>
          <Container margin="0 0 8 0">
            <Text>
              Before we begin your application, we need to ask you a question to confirm the {getProductName()} is right
              for you.
            </Text>
          </Container>
          <Container margin="0 0 3 0">
            <strong>
              {byCard({
                [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: 'Are you after a credit card that gives you:',
                default: 'Are you after a credit card that gives you access to:',
              })}
            </strong>
          </Container>
          <Container margin="0 0 3 0">
            <List>
              {benefitList.map(benefit => (
                <List.Item>{benefit}</List.Item>
              ))}
            </List>
          </Container>
          <Container margin="8 0" margin-md="0">
            <ButtonToggle
              name="consentGiven"
              options={[
                {
                  label: 'Yes',
                  value: 'yes',
                },
                {
                  label: 'No',
                  value: 'no',
                },
              ]}
              testId="landing-consent-toggle"
            />
          </Container>
        </>
      ),
    },
    {
      condition: () => formData?.state?.values?.consentGiven === 'no',
      left: "Looks like this product isn't right for you",
      right: (
        <LandingContentItem>
          <div>
            <Text>Unfortunately, the product you have selected does not meet your needs.</Text>
            <Text>
              For more information or access to other products, please visit{' '}
              <Link
                href="https://www.latitudefinancial.com.au"
                newTab
                onClick={() => {
                  trackEventSender({
                    category: 'application',
                    action: 'application-started',
                    location: formTealiumLocation,
                    label: 'latitude home page',
                  });
                }}
              >
                latitudefinancial.com.au
              </Link>
            </Text>
          </div>
        </LandingContentItem>
      ),
    },
    {
      condition: () => formData?.state?.values?.consentGiven === 'yes',
      left: 'By starting this application you agree:',
      right: (
        <List>
          <List.Item>
            <Link
              newTab
              href={byCard({
                [PRODUCT_CODE_AU_GEM]: OverviewSources.AU.latitude_gem_visa.url,
                [PRODUCT_CODE_GO]: OverviewSources.AU.latitude_go_card.url,
                [PRODUCT_CODE_AFS]: OverviewSources.AU.latitude_afs_card.url,
                [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: OverviewSources.AU.latitude_28d_mastercard.url,
              })}
              onClick={() =>
                trackEventSender({
                  action: 'external-link',
                  label: config.productName,
                  location: formTealiumLocation,
                })
              }
            >
              {byCard({
                [PRODUCT_CODE_AU_GEM]: OverviewSources.AU.latitude_gem_visa.label,
                [PRODUCT_CODE_GO]: OverviewSources.AU.latitude_go_card.label,
                [PRODUCT_CODE_AFS]: OverviewSources.AU.latitude_afs_card.label,
                [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: getProductName(),
                default: '',
              })}
            </Link>{' '}
            {byCard({
              [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: 'meets your key credit card requirements.',
              default: 'is a credit card which gives you access to interest free plans at participating retailers.',
            })}
          </List.Item>
          <List.Item>
            You have read, understood and agree with the <Link onClick={openPrivacyNoticeModal}>Privacy Notice</Link>,{' '}
            <Link
              newTab
              href={keyFactsSheetLink.url}
              onClick={() =>
                trackEventSender({
                  location: formTealiumLocation,
                  label: keyFactsSheetLink.label,
                })
              }
            >
              {keyFactsSheetLink.label}
            </Link>
            ,{' '}
            <Link
              newTab
              href={conditionsOfUseLink.url}
              onClick={() =>
                trackEventSender({
                  location: formTealiumLocation,
                  label: conditionsOfUseLink.label,
                })
              }
            >
              {conditionsOfUseLink.label}
            </Link>
            , <Link onClick={openDeclarationModal}>Declaration and Credit Application</Link>. These documents explain
            how your personal information is used, the product's features and terms under which this product operates.
          </List.Item>
          <List.Item>To let us complete a credit check after you've provided your personal details.</List.Item>
          <List.Item>
            To electronically receive your credit contract as set out in the Financial Table and Conditions of Use.
          </List.Item>
          <List.Item>
            To electronically verify your income using a secure process with Verifier (see{' '}
            <Link onClick={openVerifierModal}>Verifier's terms of service</Link>
            ). If your income can't be verified this way, we will provide alternatives.
          </List.Item>
        </List>
      ),
    },
    {
      condition: () => formData?.state?.values?.consentGiven === 'yes',
      right: (
        <StartApplicationButton
          backgroundColor={theme.newStageButtonColor}
          disabled={isAdblockCheckComplete && isAdblockerDetected && !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP}
          loading={loading}
          theme={theme}
        >
          {APPLY_BTN_LABEL}
        </StartApplicationButton>
      ),
    },
    {
      condition: () => isAdblockCheckComplete && isAdblockerDetected && !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP,
      right: (
        <Message severity="stop" bg="secondary.pink.t30">
          Please turn off your adblocker for the application to work and refresh your browser once it is done.
        </Message>
      ),
    },
  ];

  return (
    <>
      <VerifierModal isOpen={verifierModalOpen} onClose={closeVerifierModal} />
      <DeclarationModal isOpen={declarationModalOpen} onClose={closeDeclarationModal} />
      <PrivacyNoticeModal isOpen={privacyNoticeModalOpen} onClose={closePrivacyNoticeModal} />
      <ResponsiveList list={items} formData={formData} keyPrefix="right-product-for-you" />
    </>
  );
};
