export const OverviewSources = {
  NZ: {
    visit_our_website: {
      label: 'visit our website',
      url: 'https://www.gemfinance.co.nz/',
    },
    privacy_acknowledgement_and_authorisation: {
      label: 'Important Privacy Notice',
      url: 'https://assets.latitudefinancial.com/gem-essentials/nz-gem-visa-privacy-notice.pdf',
    },
    gem_visa_conditions_of_use: {
      label: 'Conditions of Use',
      url:
        'https://assets.gemfinance.co.nz/legals/conditions-of-use/gemvisa-nz/cou.pdf?_ga=2.236588865.1323252382.1615416779-941858851.1587360214',
    },
    initial_disclosure_statement: {
      label: 'Initial Disclosure Statement',
      url: 'https://assets.latitudefinancial.com/gem-essentials/nz-gem-visa-ids.pdf',
    },
    important_information: {
      label: 'Important Information',
      url: 'https://www.gemfinance.co.nz/gem-visa-info/',
    },
    gemfinance_url: {
      label: 'gemfinance.co.nz',
      url: 'http://gemfinance.co.nz/',
    },
  },
  AU: {
    visit_our_website: {
      label: 'visit our website',
      url: 'https://www.gemfinance.co.nz/',
    },
    lfs_credit_cards: {
      label: 'latitudefinancial.com.au',
      url: 'https://latitudefinancial.com.au',
    },
    latitude_gem_visa: {
      label: 'Latitude Gem Visa',
      url:
        'https://www.latitudefinancial.com.au/credit-cards/gem-visa-card.html?_ga=2.258137802.1355450623.1583383490-1396169284.1569977644',
    },
    latitude_go_card: {
      label: 'Latitude GO Mastercard®',
      url: 'https://www.latitudefinancial.com.au/credit-cards/gomastercard',
    },
    latitude_afs_card: {
      label: 'CreditLine',
      url: 'https://www.latitudefinancial.com.au/credit-cards/apple-creditline',
    },
    latitude_28d_mastercard: {
      label: 'Latitude 28° Global Platinum Mastercard',
      url:
        'https://www.latitudefinancial.com.au/credit-cards/28-degrees/?_ga=2.258137802.1355450623.1583383490-1396169284.1569977644',
    },
    privacy_notice: {
      url: 'https://www.latitudefinancial.com.au/privacy',
      label: 'Privacy Notice',
    },
    key_facts_sheet: {
      label: 'Key Facts Sheet',
      url:
        'https://assets.latitudefinancial.com/legals/key-fact-sheet/gemvisa-au/kfs.html?_ga=2.100241886.1355450623.1583383490-1396169284.1569977644',
    },
    twenty_eight_degrees_key_facts_sheet: {
      label: 'Key Facts Sheet',
      url: 'https://assets.latitudefinancial.com/legals/key-fact-sheet/28-degrees/kfs.html',
    },
    go_key_facts_sheet: {
      label: 'Key Facts Sheet',
      url: 'https://assets.latitudefinancial.com/legals/key-fact-sheet/gomc/kfs.html',
    },
    afs_key_facts_sheet: {
      label: 'Key Facts Sheet',
      url: 'https://assets.latitudefinancial.com/legals/key-fact-sheet/creditline-afs/kfs.html',
    },
    gem_conditions_of_use: {
      label: 'Conditions of Use',
      url: 'https://assets.latitudefinancial.com/legals/conditions-of-use/gemvisa-au/cou.pdf',
    },
    twenty_eight_degrees_conditions_of_use: {
      label: 'Conditions of Use',
      url: 'https://assets.latitudefinancial.com/legals/conditions-of-use/28degrees-au/cou.pdf',
    },
    go_conditions_of_use: {
      label: 'Conditions of Use',
      url: 'https://assets.latitudefinancial.com/legals/conditions-of-use/gomc-au/cou.pdf',
    },
    afs_conditions_of_use: {
      label: 'Conditions of Use',
      url: 'https://assets.latitudefinancial.com/legals/conditions-of-use/creditline-afs/cou.pdf',
    },
    important_information: {
      label: 'Important Information',
      url: 'https://www.gemfinance.co.nz/gem-visa-info/',
    },
    privacy_acknowledgement_and_authorisation: {
      label: 'Important Privacy Notice',
      url: 'https://dsa.apply.gemvisa.co.nz/eapps/PrivacyPolicyPrint.faces?cardType=gemvisanz',
    },
    gem_visa_conditions_of_use: {
      label: 'Conditions of Use',
      url:
        'https://assets.gemfinance.co.nz/legals/conditions-of-use/gemvisa-nz/cou.pdf?_ga=2.236588865.1323252382.1615416779-941858851.1587360214',
    },
    initial_disclosure_statement: {
      label: 'Initial Disclosure Statement',
      url: 'https://assets.latitudefinancial.com/gem-essentials/nz-gem-visa-ids.pdf',
    },
    gemfinance_url: {
      label: 'gemfinance.co.nz',
      url: 'http://gemfinance.co.nz/',
    },
    learnMore: {
      label: 'Learn more',
      url: 'https://www.davidjones.com/faq/david-jones-american-express-card',
    },
    auPostForm: {
      label: 'Download form',
      url: 'https://assets.latitudefinancial.com/forms/cards/identity-verification/identity-verification.pdf',
    },
    findAuPost: {
      label: 'Bank@Post facility',
      url: 'https://auspost.com.au/locate/?services=113',
    },
    findAppleStore: {
      storeName: 'Apple',
      label: 'Find Apple Store',
      url: 'https://www.apple.com/au/retail/',
    },
    afs_apple: {
      label: 'Apple',
      url: 'https://www.apple.com/au/shop/browse/financing',
    },
    afs_rates_fees: {
      label: 'Rates and Fees',
      url: 'https://www.latitudefinancial.com.au/credit-cards/apple-creditline',
    },
    interest_free_detail: {
      label: 'Find more details',
      url:
        'https://latitudefs.zendesk.com/hc/en-au/articles/4409706884113-Latitude-GO-Mastercard-s-6-months-Interest-Free',
    },
    terms_conditions: {
      label: 'Terms and Conditions',
      url: 'https://www.latitudefinancial.com.au/terms-and-conditions',
    },
    security: {
      label: 'Security',
      url: 'https://www.latitudefinancial.com.au/security',
    },
  },
};

export const FooterSources = {
  privacy_policy: {
    label: 'Privacy and Credit Reporting Policy',
    url: 'https://www.gemfinance.co.nz/privacy/',
  },
  terms_and_conditions: {
    label: 'Terms and Conditions of Use',
    url: 'https://www.gemfinance.co.nz/terms-and-conditions/',
  },
  security: {
    label: 'Security',
    url: 'https://www.gemfinance.co.nz/security/',
  },
};

export const OutcomeApprovedSources = {
  pdf: {
    initial_disclosure_statement: {
      label: 'Initial Disclosure Statement',
      url: 'https://assets.latitudefinancial.com/gem-essentials/nz-gem-visa-ids.pdf',
    },
    initial_disclosure_statement_FY2023_Q3: {
      label: 'Initial Disclosure Statement',
      url: 'https://assets.latitudefinancial.com/gem-essentials/nz-gem-visa-ids.pdf',
    },
    conditions_of_use: {
      label: 'Conditions of Use',
      url: 'https://assets.latitudefinancial.com/legals/conditions-of-use/gemvisa-nz/cou.pdf',
    },
  },
  shoppingInformation: {
    use_account_number: {
      label: 'Get started now',
      location: 'Shop with Interest Free, from 12 months to 60 months',
      url: 'https://www.gemfinance.co.nz/credit-cards/interest-free-offers/',
    },
    google_store: {
      label: 'Google Play',
      location: 'outcome',
      url: 'https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&hl=en_NZ&gl=US',
    },
    apple_store: {
      label: 'App Store',
      location: 'outcome',
      url: 'https://apps.apple.com/nz/app/latitude-app/id1373059866',
    },
  },
  featureInformation: {
    interest_free: {
      label: 'more about 6 months Interest Free',
      location: '6 months interest free',
      url: 'https://www.gemfinance.co.nz/credit-cards/gem-visa-card/',
    },
    long_term_interest_free: {
      label: 'more about long term interest free',
      location: 'Get long term interest free - from 6 to 60 months',
      url: 'https://www.gemfinance.co.nz/credit-cards/interest-free-offers/',
    },
  },
};

export const OutcomeApprovedSourcesAu = {
  shoppingInformation: {
    google_store: {
      label: 'Google Play',
      location: 'outcome',
      url: 'https://play.google.com/store/apps/details?id=com.latitudefinancial.latitudeapp&hl=en_AU&gl=AU',
    },
    apple_store: {
      label: 'App Store',
      location: 'outcome',
      url: 'https://apps.apple.com/au/app/latitude-app/id1373059866',
    },
  },
  appleOnlineStore: {
    label: 'Apple Online Store',
    location: 'outcome',
    url: 'https://www.apple.com/au/shop/browse/financing',
  },
  findAppleStore: {
    label: 'Find an Apple Retail Store',
    location: 'outcome',
    url:
      'https://www.apple.com/au/retail/?_gl=1*1dosvma*_ga*MTQxNzk2ODAzOC4xNzEzNDA3OTY3*_ga_EJBPRE662F*MTcyMjQyNTE1My4xMS4xLjE3MjI0MjU3NjQuMC4wLjA',
  },
  merchantList: {
    label: 'Australia’s leading retailers',
    url: 'https://www.latitudefinancial.com.au/interest-free/find-a-store',
  },
};

export const ProductComparisonLinks = {
  dj_premiere_compare: {
    url: 'https://www.davidjones.com/david-jones-credit-card/compare/premiere',
  },
  dj_prestige_compare: {
    url: 'https://www.davidjones.com/david-jones-credit-card/compare/prestige',
  },
  dj_compare_cards: {
    url: 'https://www.davidjones.com/david-jones-credit-card/compare',
  },
  about_us: {
    url: 'https://www.latitudefinancial.com.au/about-us',
  },
};

export const AdditionalCardHolderLinks = {
  privacy_policy: {
    url: 'https://www.latitudefinancial.com.au/privacy',
    label: 'Privacy Policy',
  },
};
